



























import { hexToRgba } from '@/calendesk/prototypes/hexToRgba'
import EmployeeCarouselModel from '@/calendesk/models/EmployeeCarouselModel'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
export default mixins(DraftElement).extend({
  name: 'EmployeeCarouselItem',
  model: {
    prop: 'employee',
    event: 'select'
  },
  props: {
    item: {
      type: EmployeeCarouselModel,
      required: true
    },
    isChecked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedBackgroundColor (): string | null {
      if (this.isChecked) {
        return hexToRgba(this.draftConfiguration.wb_color_primary__color__, 0.12) as string
      } else {
        return null
      }
    }
  },
  methods: {
    select () {
      this.$emit('select', this.item)
    }
  }
})

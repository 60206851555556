import Employee from '@/calendesk/models/DTO/Response/Employee'
import { trans } from '@/calendesk/prototypes/trans'

export default class EmployeeCarouselModel {
  public employee: Employee | null

  constructor (employee: Employee | null) {
    this.employee = employee
  }

  public getAvatarUrl () {
    if (this.employee) {
      return this.employee.getDefaultImageUrl()
    }

    return require('@/assets/placeholder.jpg')
  }

  public getTitle (): string {
    if (this.employee) {
      return this.employee.getFullName()
    }

    return trans('any_person')
  }

  public getSubtitle (): string | null {
    if (this.employee) {
      return this.employee.title
    }

    return null
  }
}

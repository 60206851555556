






















import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import { ServiceLocationType } from '@/calendesk/models/DTO/Response/ServiceLocationType'
import Vue from 'vue'
export default Vue.extend({
  name: 'LocationItem',
  props: {
    location: {
      type: ServiceLocation,
      default: null
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getIconForLocationType (type: ServiceLocationType) {
      switch (type) {
        case 1:
        case 4:
          return '$marker'
        case 2:
          return '$phone'
        default:
          return null
      }
    },
    getImageForLocationType (type: ServiceLocationType) {
      switch (type) {
        case 3:
          return require('@/assets/google-meet-small-logo.png')
        case 5:
          return require('@/assets/zoom-small-logo.png')
        case 6:
          return require('@/assets/teams-logo-small.png')
        case 7:
          return require('@/assets/skype-logo-small.png')
        case 8:
          return require('@/assets/whatsapp-logo-small.png')
        default:
          return null
      }
    },
    getLabelForServiceLocation (serviceLocation: ServiceLocation): string {
      let label = `${this.$trans('service_location_' + serviceLocation.type)}`

      if (serviceLocation.name) {
        label = serviceLocation.name
      }

      if (serviceLocation.data) {
        label += ` (${serviceLocation.data})`
      }

      return label
    }
  }
})

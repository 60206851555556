







































































import Vue from 'vue'
import EmployeeCarouselItem from '@/components/dialogs/components/BookingDialog/components/EmployeeCarouselItem.vue'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import EmployeeCarouselModel from '@/calendesk/models/EmployeeCarouselModel'
export default Vue.extend({
  name: 'EmployeeCarousel',
  components: {
    EmployeeCarouselItem
  },
  props: {
    employee: {
      type: Employee
    },
    employees: {
      type: Array as () => Employee[],
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: [] as EmployeeCarouselModel[],
      selectedItem: null as EmployeeCarouselModel | null,
      carouselWidth: 360,
      carouselMaxWidth: 360,
      scrollLength: 144,
      scroll: 0,
      disabledPrevBtn: true,
      disabledNextBtn: false
    }
  },
  watch: {
    selectedItem (selectedItem: EmployeeCarouselModel) {
      if (selectedItem && selectedItem.employee) {
        this.$emit('selected', selectedItem.employee)
      }
    },
    scroll () {
      this.checkDisabledPrevBtn()
      this.checkDisabledNextBtn()
    },
    '$vuetify.breakpoint.width' () {
      this.resizeCarousel()
    }
  },
  mounted () {
    this.addScrollEventListener()
    const items = Array<EmployeeCarouselModel>()

    if (this.employees && this.employees.length > 1) {
      items.push(new EmployeeCarouselModel(null))
    }

    this.employees.forEach((employee: Employee) => {
      items.push(new EmployeeCarouselModel(employee))
    })

    this.items = items
    this.selectedItem = this.items[0]

    this.resizeCarousel()
  },
  methods: {
    isChecked (item: EmployeeCarouselModel) {
      if (!item.employee && !this.selectedItem?.employee) {
        // any person and employee is null
        return true
      }

      return (item.employee && this.selectedItem?.employee && item.employee.id === this.selectedItem.employee.id)
    },
    resizeCarousel () {
      const refreshCarouselData = setInterval(() => {
        if (this.$refs.carousel) {
          this.carouselWidth = (this.$refs.carousel as any).clientWidth
          this.scrollLength = this.carouselWidth / this.scrollLength * this.scrollLength
          this.carouselMaxWidth = (this.$refs.carouselContainer as any).clientWidth
          this.checkDisabledPrevBtn()
          this.checkDisabledNextBtn()
          if (this.carouselWidth !== 0) {
            clearInterval(refreshCarouselData)
          }
        }
      }, 100)
    },
    prevClick () {
      if (this.scroll <= 0) {
        return
      }
      this.scroll -= this.scrollLength
      const element = this.$refs.carouselItems as Element
      element.scroll({
        left: this.scroll,
        behavior: 'smooth'
      })
    },
    nextClick () {
      if (this.scroll - this.scrollLength >= this.carouselMaxWidth) {
        return
      }
      this.scroll += this.scrollLength as number
      (this.$refs.carouselItems as any).scroll({
        left: this.scroll,
        behavior: 'smooth'
      })
    },
    checkDisabledPrevBtn () {
      if (this.scroll <= 0) {
        this.disabledPrevBtn = true
        this.scroll = 0
      } else {
        this.disabledPrevBtn = false
      }
    },
    checkDisabledNextBtn () {
      if (this.scroll + this.scrollLength >= this.carouselMaxWidth) {
        this.disabledNextBtn = true
      } else {
        this.disabledNextBtn = false
      }
    },
    scrollEvent (e: any) {
      this.scroll = e.target.scrollLeft
    },
    addScrollEventListener () {
      const employeeCarouselItems = document.querySelector('.employee-carousel__items')
      if (!employeeCarouselItems) return
      employeeCarouselItems.addEventListener('scroll', this.scrollEvent)
    }
  }

})

import Service from '@/calendesk/models/DTO/Response/Service'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import moment from 'moment/moment'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import Product from '@/calendesk/models/DTO/Response/Product'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import { capitalize } from '@/calendesk/filters/capitalize'
import timeNotation from '@/calendesk/filters/timeNotation'

export default class BookingEvent {
  public id: string
  public service: Service
  public employee: Employee
  public serviceType: ServiceType | null
  public startDate: string
  public startTime: string
  public endDate: string
  public endTime: string
  public selectedDuration: number | null
  public customerTimeZone: string | null
  public selectedProducts: Array<Product> = []
  public userRequestedEmployee: boolean
  public location: ServiceLocation | null

  public constructor (
    service: Service,
    employee: Employee,
    serviceType: ServiceType | null,
    startDate: string,
    startTime: string,
    endDate: string | null = null,
    endTime: string | null = null,
    selectedDuration: number | null = null,
    customerTimeZone: string | null = null,
    userRequestedEmployee = false,
    location: ServiceLocation | null = null
  ) {
    this.id = (Math.random() + 1).toString(36).substring(2)
    this.service = service
    this.employee = employee
    this.serviceType = serviceType
    this.startDate = startDate
    this.startTime = startTime
    this.selectedDuration = selectedDuration
    this.customerTimeZone = customerTimeZone
    this.userRequestedEmployee = userRequestedEmployee
    this.location = location

    if (!endDate || !endTime) {
      const duration = serviceType ? serviceType.duration : service.duration
      const endDateTime = moment(this.startDate + ' ' + this.startTime).add(duration, 'minutes')

      this.endDate = endDateTime.format(DateTimeFormats.FULL)
      this.endTime = endDateTime.format(DateTimeFormats.TIME)
    } else {
      this.endDate = endDate
      this.endTime = endTime
    }
  }

  public getDuration () {
    if (this.serviceType) {
      return this.serviceType.duration
    }

    return this.service.duration
  }

  public getPrice () {
    let basePrice = this.service.price
    if (this.serviceType) {
      basePrice = this.serviceType.price
    }

    let productPrice = 0

    if (this.selectedProducts) {
      this.selectedProducts.forEach(product => {
        productPrice += product.price
      })
    }

    return basePrice + productPrice
  }

  public getName () {
    if (this.serviceType) {
      return this.service.name + ' (' + this.serviceType.name + ')'
    }

    return this.service.name
  }

  public sameDay () {
    return this.startDate === this.endDate
  }

  public getSelectedProductIds (): number[] {
    if (this.selectedProducts && this.selectedProducts.length > 0) {
      return this.selectedProducts.map(product => product.id)
    }

    return []
  }

  public getHumanDateTime (hideBookingEndTime = false): string {
    const sameDay = this.sameDay()

    // Function to format the date and capitalize the first letter of the weekday
    const formatWithCapitalizedWeekday = (dateTime: object, format: string) => {
      const formattedString = moment(dateTime).format(format)
      return capitalize(formattedString)
    }

    const startMoment = moment(`${this.startDate} ${this.startTime}`)
    const endMoment = moment(`${this.endDate} ${this.endTime}`)

    const startDate = formatWithCapitalizedWeekday(startMoment, DateTimeFormats.FULL_WITH_MONTH_TEXT_AND_DATE)
    const endDate = formatWithCapitalizedWeekday(endMoment, DateTimeFormats.FULL_WITH_MONTH_TEXT_AND_DATE)

    // Format time using timeNotation function
    const startTimeFormatted = timeNotation(startMoment.format('HH:mm'))
    const endTimeFormatted = timeNotation(endMoment.format('HH:mm'))

    let subtitle = sameDay
      ? `${startDate}, ${startTimeFormatted} - ${endTimeFormatted}`
      : `${startDate} ${startTimeFormatted} - ${endDate} ${endTimeFormatted}`

    if (hideBookingEndTime) {
      subtitle = sameDay
        ? `${startDate}, ${startTimeFormatted}`
        : `${startDate} ${startTimeFormatted} - ${endDate}`
    }

    return capitalize(subtitle)
  }
}
